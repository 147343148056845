import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';
import Slide1 from 'src/slides/desktop/ua/chapter_4/sub_8/slide1';
import Slide2 from 'src/slides/desktop/ua/chapter_4/sub_8/slide2';
import Slide3 from 'src/slides/desktop/ua/chapter_4/sub_8/slide3';
import Slide4 from 'src/slides/desktop/ua/chapter_4/sub_8/slide4';
import WrapperMobile from 'src/slides/mobile/ua/chapter_4/sub_8/wrapperMobile';
import { isMobile } from 'react-device-detect';
import SEO from 'src/components/_shared/seo';

const allSlides = [<Slide1 />, <Slide2 />, <Slide3 />, <Slide4 />];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Гей! Поляк в багнети I Варшавська битва" lang="ua" description="Роль добровольців в польсько-більшовицької війни." />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO title="Гей! Поляк в багнети I Варшавська битва" lang="ua" description="Роль добровольців в польсько-більшовицької війни." />
    <WrapperMobile />
  </>
);

// export

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
